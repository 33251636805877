import { UseRequestBodyPagination, UseRequestBodySort } from "api/hooks"
import { FilesList } from "Components/File/types"
import { ModalStateProps } from "../Modal/types"

export type YandexFile = {
  name: string
  url: string
  size: number
  uploadDttm: string
}

export type YandexFilesTableData = {
  date: [string, string] | [string, null]
  file: YandexFile | null
}

export type YandexFilesTableState = {
  total_count: number
  pending: boolean
  items: YandexFilesTableData[]
}

export type YandexFilesFilterProps = {
  pagination: UseRequestBodyPagination
  sort: UseRequestBodySort
}

export type YandexTableRecord = {
  dates: [string, string | null]
  file: FilesList
}

export enum YandexTableStatusRus {
  uploaded = `Загружен`,
  absent = `Отсутствует`
}

export enum YandexTableStatus {
  uploaded = `uploaded`,
  absent = `absent`
}

export type getColumnsProps = {
  filterState: YandexFilesFilterProps
  setFilterState: (val: YandexFilesFilterProps) => void
  setModalState: (val: ModalStateProps) => void
  fileNameHandler: (file: YandexFile) => void
}
