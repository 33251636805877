export const mainText = {
  title: `Баланс клуба`,
  tooltip: `Раздел позволяет направить заявку на пополнение счета клуба для возможности выплаты выигрышей гостям безналичным способом а также для пополнения счета клуба в сервисе Яндекс такси. Можно отследить статус заявки и воспользоваться фильтрацией для поиска нужной информации. В разделе доступен функционал дублирования, который ускорит заполнение новой заявки, скорректировав её при необходимости, и отмены заявки, только если заявка в статусе Новая.`,
  exelUrl: `v1/order/qiwi/excel`,
  exelModal: {
    title: `Не можем выгрузить`,
    description: `Слишком много данных. Выгрузка будет доступна при выборе только одного клуба в фильтре`
  },
  new: `Оформить заявку`
}

export const initialReq = {
  pagination: {
    limit: 30,
    offset: 0
  },
  filters: {}
}

export const checkHallCrunch = (current_hall: number) =>
  [
    11,
    25,
    46,
    99,
    101,
    119,
    141,
    164,
    186,
    212,
    223,
    253,
    293,
    420,
    501,
    588,
    600,
    629,
    643,
    707,
    759,
    783,
    806,
    820,
    861,
    884,
    980,
    987,
    995,
    1012,
    1035,
    1045,
    1046,
    1076,
    1108,
    1153,
    1199,
    1208,
    1226,
    1229,
    1234,
    1237,
    1239,
    1269,
    1301,
    1302,
    1334,
    1338,
    1364,
    1388,
    1405,
    1422,
    1428,
    1457,
    1460,
    1470,
    1488,
    1490,
    1491,
    1494,
    1497,
    1500,
    1505,
    1509,
    1517,
    1533,
    1540,
    1544,
    1561,
    1566,
    1570,
    1585,
    1599,
    1605,
    1613,
    1638,
    1649,
    1650,
    1651,
    1655,
    1660,
    1663,
    1664,
    1669,
    1684,
    1699,
    1700,
    1701,
    1704,
    1706,
    1708,
    1710,
    1737,
    1738,
    1744,
    1757,
    1760,
    1793,
    1796,
    1799,
    1806,
    1809,
    1829,
    1851,
    1861,
    1874,
    1876,
    1877,
    1878,
    1879,
    1891,
    1895,
    1897,
    1902,
    1908,
    1914,
    1918,
    1923,
    1927,
    1928,
    1933,
    1935,
    1940,
    1947,
    1948,
    1950,
    1955,
    1957,
    1960,
    1966,
    1969,
    1975,
    1980,
    1982,
    1983,
    1984,
    1987,
    1990,
    1991,
    2008,
    2013,
    2015,
    2018,
    2021,
    2024,
    2032,
    2038,
    2041,
    2042,
    2044,
    2047,
    2048,
    2049,
    2052,
    2055,
    2056,
    2057,
    2058,
    2059,
    2064,
    2067,
    2068,
    2069,
    2076,
    2079
  ].includes(current_hall)
