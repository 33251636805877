import React from "react"
import {
  getColumnsProps,
  YandexFile,
  YandexTableStatus,
  YandexTableStatusRus
} from "./types"
import { TableHeaderSort } from "Components/TableNew"
import { ReactComponent as AttachIcon } from "Components/icons/attach.svg"
import { ReactComponent as EditIcon } from "Components/icons/crud/edit_20.svg"
import { ReactComponent as StatusCommentIcon } from "Components/icons/info.svg"
import moment from "moment"
import { PickerFormats } from "Components/FilterInfoPanel/types"
import TooltipComponent from "Components/Tooltip"

export const yandexFileTableFields = {
  dates: `dates`,
  datesTitle: `Период`,
  file: `file`,
  fileTitle: `Файл`,
  statusTitle: `Статус`,
  uploadDateTitle: `Дата и время загрузки`,
  emptyFile: `-`
}

export const yandexFileFilterFields = {
  date: `date`,
  status: `status`
}

export const downloadModalTitleText = `Прикрепленный файл`

const yandexStatusToolip = {
  upload: `Файл успешно добавлен`,
  absent: `Добавьте файл, после этого статус изменится на “Загружен”`
}

export const getColumns = ({
  filterState,
  setFilterState,
  setModalState,
  fileNameHandler
}: getColumnsProps) => {
  return [
    {
      title: () => (
        <TableHeaderSort
          className="MarginLeft24"
          sort={filterState.sort}
          field={yandexFileTableFields.dates}
          setSort={newSort => {
            setFilterState({
              ...filterState,
              sort: newSort
            })
          }}
          title={yandexFileTableFields.datesTitle}
        />
      ),
      width: 171,
      dataIndex: yandexFileTableFields.dates,
      key: yandexFileTableFields.dates,
      render: (dates: string) => {
        return (
          <div className="MarginLeft24">
            {dates[1]
              ? `${moment(dates[0]).format(
                  PickerFormats.dayMonthYear
                )} - ${moment(dates[1]).format(PickerFormats.dayMonthYear)}`
              : `${moment(dates[0]).format(PickerFormats.dayMonthYear)}`}
          </div>
        )
      }
    },
    {
      title: () => (
        <TableHeaderSort
          sort={filterState.sort}
          field={yandexFileTableFields.file}
          setSort={newSort => {
            setFilterState({
              ...filterState,
              sort: newSort
            })
          }}
          title={yandexFileTableFields.statusTitle}
        />
      ),
      width: 200,
      dataIndex: yandexFileTableFields.file,
      key: yandexFileTableFields.file,
      render: (file: YandexFile | null) => {
        const statusRusText = file
          ? YandexTableStatusRus.uploaded
          : YandexTableStatusRus.absent

        const statusEng = file
          ? YandexTableStatus.uploaded
          : YandexTableStatus.absent

        return (
          <div
            className={`YandexStatusTableColumn YandexStatus-${statusEng} FlexRow`}
          >
            <span className="StatusText">{statusRusText}</span>

            <div className="MarginLeftAuto">
              <TooltipComponent
                title={
                  file ? yandexStatusToolip.upload : yandexStatusToolip.absent
                }
                direction="top"
              >
                <div className="YandexStatusTableComment">
                  <StatusCommentIcon />
                </div>
              </TooltipComponent>
            </div>
          </div>
        )
      }
    },
    {
      title: yandexFileTableFields.fileTitle,
      width: 324,
      dataIndex: yandexFileTableFields.file,
      key: yandexFileTableFields.file,
      render: (file: YandexFile | null) => {
        const stringToShow =
          file && file.name ? file.name : yandexFileTableFields.emptyFile

        return (
          <div
            className={file ? "YandexFileName" : ""}
            onClick={() => {
              file && fileNameHandler(file)
            }}
          >
            {file && file.name && (
              <div>
                <AttachIcon />
              </div>
            )}
            <div>{stringToShow}</div>
          </div>
        )
      }
    },
    {
      title: yandexFileTableFields.uploadDateTitle,
      width: 150,
      dataIndex: yandexFileTableFields.file,
      key: yandexFileTableFields.file,
      render: (file: YandexFile | null) => {
        return file && file.uploadDttm
          ? moment(file.uploadDttm).format(`DD.MM.YYYY HH:mm`)
          : yandexFileTableFields.emptyFile
      }
    },
    {
      title: "",
      width: 20,
      dataIndex: "",
      key: "",
      render: (record: any) => {
        return (
          <div className="PayrollTableEditItem YandexPayrollTableEditItem">
            {record.file && (
              <div
                onClick={() => setModalState({ open: true, editData: record })}
              >
                <EditIcon />
              </div>
            )}
          </div>
        )
      }
    }
  ]
}
