import { getFilterStateFromLocalStorage } from "api/helpers"
import { isEmpty } from "lodash"
import {
  GetYandexDashboards,
  YandexDashboardReportProps,
  YandexHistoryFilter,
  YandexReportItem
} from "./types"
import moment from "moment/moment"
import { PickerFormats } from "Components/FilterInfoPanel/types"
import React from "react"
import { ReactComponent as InfoIcon } from "Components/icons/info.svg"
import { TitleTooltipComponent } from "Components/Title/Title"
import betRequest from "api/request"
import { Moment } from "moment"
import { UseRequestBodyPagination } from "api/hooks"
import TooltipComponent from "Components/Tooltip"
import { parseDateByShift, parseDateToBack } from "constants/index"

export const yandexHistoryConstants = {
  YANDEX_HISTORY_FILTER: `YANDEX_HISTORY_FILTER`,
  url: `v1/hall/yandex-taxi`,
  urlDetails: `v1/hall/yandex-taxi/details`,
  urlXls: `v1/hall/yandex-taxi/excel`,
  urlFiles: `v1/hall/yandex-taxi/files`,
  nonActiveTitle: `Чтобы получить отчёт, выберите нужные фильтры и нажмите кнопку «Применить»`,
  dateFormat: `YYYY-MM-DDT00:00:00.000+03:00`,
  urlDashboard: `v1/hall/yandex-taxi/dashboards`,
  dashboardTooltip: `Баланс клуба в приложении Яндекс такси для Бизнеса. Загрузка информации о поездках прошедшего дня и поездках за выходные дни выполняется утром в первый рабочий день. Поэтому текущий баланс в приложении Яндекс может отличаться от баланса в личном кабинете.`,
  dashboardDateFormat: `DD.MM.YYYY`,
  dashboardModalColor: `#FFF9DB`,
  dashboardModalTitle: `Информация по клубам`,
  dashboardColumn: `Баланс, ₽`,
  startDayFilter: `2024-08-01`
}

export const yandexDisabledRangeDates = (currentDate: Moment | null) => {
  const startDate = moment(yandexHistoryConstants.startDayFilter)
  const today = moment()
  return currentDate
    ? !currentDate.isBetween(startDate, today, "day", "[]")
    : false
}

export const getYandexDashboardsData = ({
  filterState,
  setDashBoardState,
  dashBoardState,
  logout,
  history
}: GetYandexDashboards) => {
  setDashBoardState({
    ...dashBoardState,
    pending: true
  })

  betRequest({
    method: `POST`,
    url: yandexHistoryConstants.urlDashboard,
    requestBody: {
      filter: { halls: filterState.data.halls }
    },
    history,
    logout
  })
    .then(({ data }: { data: YandexDashboardReportProps }) => {
      setDashBoardState({
        ...dashBoardState,
        ...data,
        pending: false
      })
    })
    .catch(() =>
      setDashBoardState({
        ...dashBoardState,
        pending: false
      })
    )
}

export const yandexHistoryTableFields = {
  date: `date`,
  dateTitle: `Дата`,
  agency_fee: `agency_fee`,
  agencyFeeTitle: `Агентское вознаграждение\nс НДС, ₽`,
  number_of_trips: `number_of_trips`,
  number_of_tripsTitle: `Количество поездок`,
  file_id: `file_id`,
  sum: `sum`,
  sumTitle: `Стоимость, ₽`,
  amount: `amount`,
  amountTitle: `Итого, ₽`,
  absentText: `Файл не загружен`,
  tooltip: `Стоимость поездки с учетом времени ожидания и проезда по платной дороге (при наличии)`
}

export const yandexHistoryDetailsTableFields = {
  dateTitle: `Время\nзаказа`,
  hallTitle: `Клуб`,
  address_startTitle: `Подача`,
  address_intermediateTitle: `Промежуточный`,
  address_endTitle: `Конечный`,
  tariffTitle: `Тариф`,
  sumTitle: `Стоимость, ₽`,
  agency_feeTitle: `Агентское\nвознаграждение\nс НДС, ₽`,
  amountTitle: `Итого, ₽`,
  date: `date`,
  hall: `hall`,
  address_start: `address_start`,
  address_intermediate: `address_intermediate`,
  address_end: `address_end`,
  tariff: `tariff`,
  sum: `sum`,
  agency_fee: `agency_fee`,
  amount: `amount`
}

const formatValueWithSpaces = (val: number) =>
  !!val ? new Intl.NumberFormat("ru").format(val) : val

const rendererFileAbsent = (val: number, record: YandexReportItem) => {
  if (!record.date) {
    return formatValueWithSpaces(val)
  }
  return !record.file_id ? (
    <span className="YandexFileAbsent">
      {yandexHistoryTableFields.absentText}
    </span>
  ) : (
    formatValueWithSpaces(val)
  )
}

export const prepareYandexRequestBody = (
  filterState: YandexHistoryFilter,
  pagination: UseRequestBodyPagination
) => {
  const dates = filterState.data.dates
    ? parseDateToBack({
        date: parseDateByShift({
          dates: filterState.data.dates,
          isShift: filterState.data.isShift,
          shift: filterState.data.shift
        }),
        gmt: filterState.data.gmt,
        isFromTo: true,
        isObj: true
      })
    : null

  return {
    filter: {
      ...filterState.data,
      shift: filterState.data.isShift
        ? filterState.data.shift[0]
        : filterState.data.shift,
      dates
    },
    pagination
  }
}

const renderLongString = (str: string) => {
  const isLongText = str && str.length > 24
  return (
    <div className="FlexRow YandexAddressColumnItem FlexWrap">
      <div>{isLongText ? str.slice(0, 24) : str}</div>
      {isLongText ? (
        <TooltipComponent title={str} direction="bottom">
          ...
        </TooltipComponent>
      ) : (
        <div />
      )}
    </div>
  )
}

export const getYandexDetailsColumns = () => {
  return [
    {
      title: () => (
        <div className="MarginLeft24">
          {yandexHistoryDetailsTableFields.dateTitle}
        </div>
      ),
      width: 100,
      dataIndex: yandexHistoryDetailsTableFields.date,
      key: yandexHistoryDetailsTableFields.date,
      render: (date: string) => {
        return (
          <div className="MarginLeft24">
            {moment(date).format(PickerFormats.timeShort)}
          </div>
        )
      }
    },
    {
      title: yandexHistoryDetailsTableFields.hallTitle,
      width: 100,
      dataIndex: yandexHistoryDetailsTableFields.hall,
      key: yandexHistoryDetailsTableFields.hall
    },
    {
      title: yandexHistoryDetailsTableFields.address_startTitle,
      width: 160,
      dataIndex: yandexHistoryDetailsTableFields.address_start,
      key: yandexHistoryDetailsTableFields.address_start,
      render: renderLongString
    },
    {
      title: yandexHistoryDetailsTableFields.address_intermediateTitle,
      width: 160,
      dataIndex: yandexHistoryDetailsTableFields.address_intermediate,
      key: yandexHistoryDetailsTableFields.address_intermediate,
      render: renderLongString
    },
    {
      title: yandexHistoryDetailsTableFields.address_endTitle,
      width: 160,
      dataIndex: yandexHistoryDetailsTableFields.address_end,
      key: yandexHistoryDetailsTableFields.address_end,
      render: renderLongString
    },
    {
      title: yandexHistoryDetailsTableFields.tariffTitle,
      width: 120,
      dataIndex: yandexHistoryDetailsTableFields.tariff,
      key: yandexHistoryDetailsTableFields.tariff
    },
    {
      title: yandexHistoryDetailsTableFields.sumTitle,
      width: 120,
      dataIndex: yandexHistoryDetailsTableFields.sum,
      key: yandexHistoryDetailsTableFields.sum,
      render: formatValueWithSpaces
    },
    {
      title: yandexHistoryDetailsTableFields.agency_feeTitle,
      width: 120,
      dataIndex: yandexHistoryDetailsTableFields.agency_fee,
      key: yandexHistoryDetailsTableFields.agency_fee,
      render: formatValueWithSpaces
    },
    {
      title: yandexHistoryDetailsTableFields.amountTitle,
      width: 120,
      dataIndex: yandexHistoryDetailsTableFields.amount,
      key: yandexHistoryDetailsTableFields.amount,
      render: formatValueWithSpaces
    }
  ]
}

export const getYandexHistoryColumns = () => {
  return [
    {
      title: () => (
        <div className="MarginLeft24">{yandexHistoryTableFields.dateTitle}</div>
      ),
      width: 224,
      dataIndex: yandexHistoryTableFields.date,
      key: yandexHistoryTableFields.date,
      render: (date: string) => {
        return date ? (
          <div className="MarginLeft24">
            {moment(date).format(PickerFormats.dayMonthYear)}
          </div>
        ) : (
          <div className="MarginLeft24">{date || `Итого`}</div>
        )
      }
    },
    {
      title: yandexHistoryTableFields.number_of_tripsTitle,
      width: 224,
      dataIndex: yandexHistoryTableFields.number_of_trips,
      key: yandexHistoryTableFields.number_of_trips,
      render: rendererFileAbsent
    },
    {
      title: () => (
        <div className="YandexReport_Table_Column">
          <span className="TableHeader">
            {yandexHistoryTableFields.sumTitle}
          </span>
          <TitleTooltipComponent
            icon={<InfoIcon />}
            direction="bottom"
            tooltipText={yandexHistoryTableFields.tooltip}
          />
        </div>
      ),
      width: 224,
      dataIndex: yandexHistoryTableFields.sum,
      key: yandexHistoryTableFields.sum,
      render: rendererFileAbsent
    },
    {
      title: () => (
        <span className="TableHeader">
          {yandexHistoryTableFields.agencyFeeTitle}
        </span>
      ),
      width: 224,
      dataIndex: yandexHistoryTableFields.agency_fee,
      key: yandexHistoryTableFields.agency_fee,
      render: rendererFileAbsent
    },
    {
      title: () => (
        <span className="TableHeader">
          {yandexHistoryTableFields.amountTitle}
        </span>
      ),
      width: 224,
      dataIndex: yandexHistoryTableFields.amount,
      key: yandexHistoryTableFields.amount,
      render: rendererFileAbsent
    }
  ]
}

export const initialYandexFilterStateCreator = (
  current_hall: string
): YandexHistoryFilter => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    yandexHistoryConstants.YANDEX_HISTORY_FILTER
  )

  const initFilter = {
    halls: [current_hall],
    ...initFilterStateFromStorage
  }

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: !isEmpty(initFilterStateFromStorage),
    data: {
      dates: undefined,
      date: undefined,
      gmt: `3`,
      isShift: true,
      shift: [8, 20],
      isActive: false,
      ...initFilter
    }
  }
}
