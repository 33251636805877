import React, { useCallback, useEffect, useState } from "react"
import { useRequest, useRequestBody } from "api/hooks"
import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import NotActiveFilter from "Components/NotActiveFilter"
import { ReactComponent as NotActiveFilterIcon } from "./img/notActiveFilter.svg"
import {
  getYandexDashboardsData,
  getYandexHistoryColumns,
  prepareYandexRequestBody,
  yandexDisabledRangeDates,
  yandexHistoryConstants
} from "./constants"
import { YandexHistoryProps, YandexReport, YandexReportItem } from "./types"
import TableNew from "Components/TableNew"
import { logoutUser } from "store/auth/actions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { ModalRightDetail } from "Components/Modal"
import YandexDetails from "./YandexDetails"

const YandexHistory = ({
  filterState,
  setFilterState,
  setDashBoardState,
  dashBoardState
}: YandexHistoryProps) => {
  const { pagination, setPagination, resetPagination } = useRequestBody()

  const setFilterStateCb = useCallback(value => setFilterState(value), [])

  const dispatch = useDispatch()
  const logout: () => void = () => dispatch(logoutUser())
  const history = useHistory()

  useEffect(() => {
    filterState.isActive &&
      getYandexDashboardsData({
        filterState,
        setDashBoardState,
        dashBoardState,
        logout,
        history
      })
  }, [filterState.isActive, filterState.data.halls])

  const [reportData, setReportData] = useState<YandexReport>({
    pending: false,
    total_amount: 0,
    total_sum: 0,
    total_count: 0,
    total_agency_fee: 0,
    total_number_of_trips: 0,
    items: []
  })

  const { request } = useRequest({
    url: yandexHistoryConstants.url,
    method: `POST`,
    requestBody: prepareYandexRequestBody(filterState, pagination)
  })

  useEffect(() => {
    if (!filterState.data.dates) return

    setReportData({ ...reportData, pending: true })

    request()
      .then(({ data }: any) => {
        setReportData({
          ...reportData,
          pending: false,
          total_count: data.total_count,
          items:
            data.items.length > 0
              ? [
                  {
                    date: "",
                    agency_fee: data.total_agency_fee,
                    number_of_trips: data.total_number_of_trips,
                    sum: data.total_sum,
                    amount: data.total_amount
                  },
                  ...data.items
                ]
              : []
        })
      })
      .catch(() => {
        setReportData({ ...reportData, pending: false })
      })
  }, [
    filterState.data.halls,
    pagination.offset,
    pagination.limit,
    filterState.data.dates,
    filterState.data.shift,
    filterState.data.gmt,
    filterState.data.pagination,
    filterState.isActive
  ])

  const columns = getYandexHistoryColumns()

  const [showDetail, toggleShowDetail] = useState<boolean>(false)

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={yandexHistoryConstants.YANDEX_HISTORY_FILTER}
            filterState={filterState}
            setFilterState={setFilterStateCb}
            resetPagination={resetPagination}
            disabledRangeDates={yandexDisabledRangeDates}
            infoPanelConfig={{ withoutPeriod: true }}
            filters={[Filters.InfoPanel]}
            getDisabled={filter => !filter?.dates}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <>
            {filterState.isActive && (
              <div>
                {showDetail && (
                  <ModalRightDetail
                    initialWidth={1280}
                    close={() => {
                      toggleShowDetail(false)
                    }}
                  >
                    <YandexDetails filterState={filterState} />
                  </ModalRightDetail>
                )}
                <TableNew
                  width={1200}
                  data={reportData.items}
                  loading={reportData.pending}
                  columns={columns}
                  withTotal={true}
                  rowClassNameByRecord={(record: YandexReportItem) => {
                    //Если есть поездкт и есть файл, то добавляем класс с cursor: pointer
                    // и дата record.date тоже должна быть, чтобы первая строка с Итого не попадала
                    // (так как первая строка Итого с file_id и number_of_trips, но без даты)
                    if (
                      !!record.file_id &&
                      record.number_of_trips &&
                      record.number_of_trips > 0 &&
                      record.date
                    ) {
                      return `YandexDetailRowClickable`
                    } else {
                      return ``
                    }
                  }}
                  onRow={(record: YandexReportItem, index: number) => {
                    return (
                      index &&
                      !!record.file_id &&
                      record.number_of_trips &&
                      record.number_of_trips > 0 && {
                        onClick: () => {
                          setFilterState({
                            ...filterState,
                            data: { ...filterState.data, date: record.date }
                          })
                          toggleShowDetail(true)
                        }
                      }
                    )
                  }}
                  pagination={{
                    value: pagination,
                    total: reportData.total_count,
                    onChange: setPagination
                  }}
                />
              </div>
            )}

            {!filterState.isActive && (
              <NotActiveFilter
                title={yandexHistoryConstants.nonActiveTitle}
                icon={<NotActiveFilterIcon />}
              />
            )}
          </>
        }
      />
    </>
  )
}

export default YandexHistory
