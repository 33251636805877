import { YandexModalFormValues } from "./Modal/types"
import {
  YandexDashboardStateProps,
  YandexHistoryFilter
} from "./YandexHistory/types"
import { JSX } from "react"
import { Moment } from "moment"
import { UseRequestBodyPagination } from "../../../api/hooks"

export enum YandexActiveTabs {
  upload = `upload`,
  history = `history`
}

export type YandexFilesList = {
  mime_type: string
  name: string
  size: number
  url: string
  id?: string
}

export type PrepareYandexDataToSaveType = {
  values: YandexModalFormValues
  files: YandexFilesList[]
}

type ExelRequestProps = {
  filter: {
    dates?: { from: string; to: string }
    date?: string
    gmt: string
    isShift: boolean
    shift: [number, number]
    halls: [number]
  }
}

export type PartnerViewProps = {
  filterState: YandexHistoryFilter
  setFilterState: (val: YandexHistoryFilter) => void
  exelReq: ExelRequestProps
  titleDescription: JSX.Element
  showDashboardModal: boolean
  toggleShowDashboardModal: (val: boolean) => void
  dashBoardState: YandexDashboardStateProps
  setDashBoardState: (val: YandexDashboardStateProps) => void
  showDashboard: boolean
}

export type NonPartnerViewProps = {
  filterState: YandexHistoryFilter
  setFilterState: (val: YandexHistoryFilter) => void
  exelReq: ExelRequestProps
  titleDescription: JSX.Element
  showDashboardModal: boolean
  toggleShowDashboardModal: (val: boolean) => void
  dashBoardState: YandexDashboardStateProps
  setDashBoardState: (val: YandexDashboardStateProps) => void
  showDashboard: boolean
  toggleShowDashboard: (val: boolean) => void
}
