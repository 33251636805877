import React, { useMemo, useState } from "react"
import { useRequestParams } from "api/hooks"
import {
  YandexDashboardStateProps,
  YandexHistoryFilter
} from "./YandexHistory/types"
import moment from "moment"
import { getDateDescriptionLayout, roleConstants } from "constants/index"
import {
  initialYandexFilterStateCreator,
  yandexHistoryConstants
} from "./YandexHistory/constants"
import PartnerView from "./PartnerView"
import NonPartnerView from "./NonPartnerView"

const YandexTaxi: React.FC = () => {
  const { isPartner } = roleConstants()

  const { current_hall } = useRequestParams()

  const initFilterState = initialYandexFilterStateCreator(
    current_hall[0] as string
  )

  const [filterState, setFilterState] = useState<YandexHistoryFilter>(
    initFilterState
  )

  const titleDescription = useMemo(() => {
    if (!!filterState.data.dates) {
      return getDateDescriptionLayout({
        dates: [filterState.data.dates[0], filterState.data.dates[1]],
        shift: filterState.data.shift,
        hideTime: false,
        hidePeriodTitle: true,
        calendarIcon: true,
        isShift: filterState.data.isShift,
        show: filterState.isActive
      })
    }
    return <></>
  }, [filterState.data.dates])

  const [showDashboard, toggleShowDashboard] = useState<boolean>(
    isPartner ? filterState.isActive : false
  )

  const [showDashboardModal, toggleShowDashboardModal] = useState<boolean>(
    false
  )

  const [dashBoardState, setDashBoardState] = useState<
    YandexDashboardStateProps
  >({
    pending: false
  })

  const exelReq = {
    filter: {
      ...filterState.data,
      dates: filterState.data.dates
        ? {
            from: moment(filterState.data.dates[0]).format(
              yandexHistoryConstants.dateFormat
            ),
            to: moment(filterState.data.dates[1]).format(
              yandexHistoryConstants.dateFormat
            )
          }
        : undefined
    }
  }

  return isPartner ? (
    <PartnerView
      filterState={filterState}
      setFilterState={setFilterState}
      dashBoardState={dashBoardState}
      setDashBoardState={setDashBoardState}
      exelReq={exelReq}
      showDashboardModal={showDashboardModal}
      showDashboard={showDashboard}
      toggleShowDashboardModal={toggleShowDashboardModal}
      titleDescription={titleDescription}
    />
  ) : (
    <NonPartnerView
      filterState={filterState}
      setFilterState={setFilterState}
      exelReq={exelReq}
      titleDescription={titleDescription}
      showDashboardModal={showDashboardModal}
      toggleShowDashboardModal={toggleShowDashboardModal}
      dashBoardState={dashBoardState}
      setDashBoardState={setDashBoardState}
      showDashboard={showDashboard}
      toggleShowDashboard={toggleShowDashboard}
    ></NonPartnerView>
  )
}

export default YandexTaxi
