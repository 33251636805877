import React, { useEffect, useState } from "react"
import { useNow, useRequest, useRequestBody } from "api/hooks"
import {
  YandexFile,
  YandexFilesFilterProps,
  YandexFilesTableState
} from "./types"
import { initialYandexFileTableState } from "../constants"
import { SortOrders } from "Components/Table/types"
import { getColumns, yandexFileTableFields } from "./constants"
import TableNew from "Components/TableNew"
import YandexModal from "../Modal"
import { ModalStateProps } from "../Modal/types"
import DownloadModal from "./DownloadModal"
import { DownloadModalStateProps } from "./DownloadModal/types"
import { prepareSortFiled } from "../helper"

const YandexFiles = ({
  modalState,
  setModalState
}: {
  modalState: ModalStateProps
  setModalState: (val: ModalStateProps) => void
}) => {
  const { pagination, setPagination } = useRequestBody(20)

  const { refresh: refreshTable, timestamp: refreshTrigger } = useNow()

  const [yandexFilesFilterState, setYandexFilesFilterState] = useState<
    YandexFilesFilterProps
  >({
    pagination,
    sort: {
      field: yandexFileTableFields.dates,
      order: SortOrders.desc
    }
  })

  const [tableData, setTableData] = useState<YandexFilesTableState>(
    initialYandexFileTableState
  )

  const { request: getFilesTable } = useRequest({
    url: `v1/hall/yandex-taxi/files`,
    method: "POST",
    requestBody: {
      pagination,
      sort: {
        field: prepareSortFiled(
          yandexFilesFilterState.sort.field,
          yandexFilesFilterState
        ),
        order: yandexFilesFilterState.sort.order || SortOrders.desc
      }
    }
  })

  const requestDeps = JSON.stringify(yandexFilesFilterState)

  useEffect(() => {
    getFilesTable()
      .then(({ data }) =>
        setTableData({
          items: data.items || [],
          pending: false,
          total_count: data.total_count
        })
      )
      .catch(() => setTableData({ ...tableData, pending: false }))
  }, [requestDeps, refreshTrigger, pagination])

  const [downloadModalState, setDownloadModalState] = useState<
    DownloadModalStateProps
  >({
    open: false,
    file: undefined
  })

  const fileNameHandler = (file: YandexFile) => {
    setDownloadModalState({ open: true, file })
  }

  const columns = getColumns({
    filterState: yandexFilesFilterState,
    setFilterState: setYandexFilesFilterState,
    setModalState,
    fileNameHandler
  })

  return (
    <div>
      {modalState.open && (
        <YandexModal
          editData={modalState.editData}
          close={() => {
            setModalState({ open: false, editData: undefined })
            refreshTable()
          }}
        />
      )}
      {downloadModalState.open && (
        <DownloadModal
          file={downloadModalState.file}
          close={() => {
            setDownloadModalState({ open: false, file: undefined })
          }}
        />
      )}
      <TableNew
        width={1400}
        data={tableData.items}
        loading={tableData.pending}
        columns={columns}
        pagination={{
          value: pagination,
          onChange: pagination => setPagination(pagination),
          total: tableData.total_count
        }}
      />
    </div>
  )
}

export default YandexFiles
