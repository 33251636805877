import { NonPartnerViewProps, YandexActiveTabs } from "../types"
import Title from "Components/Title"
import { isMobile } from "constants/index"
import { YandexTabsTitleText } from "../helper"
import { ButtonPrimary } from "Components/Button"
import ExcelButton from "Components/ExcelButton"
import { yandexHistoryConstants } from "../YandexHistory/constants"
import QiwiModalComponent from "Components/QiwiModal"
import DashboardComponent from "Components/DashboardNew"
import {
  DashboardOptions,
  DashboardPrefixOptions
} from "Components/DashboardNew/constants"
import moment from "moment/moment"
import YandexFiles from "../YandexFilesTable"
import YandexHistory from "../YandexHistory"
import React, { useState } from "react"
import { ModalStateProps } from "../Modal/types"

const NonPartnerView = ({
  filterState,
  setFilterState,
  exelReq,
  titleDescription,
  showDashboardModal,
  toggleShowDashboardModal,
  dashBoardState,
  setDashBoardState,
  showDashboard,
  toggleShowDashboard
}: NonPartnerViewProps) => {
  const [modalState, setModalState] = useState<ModalStateProps>({
    open: false,
    editData: undefined
  })

  const [activeTab, setActiveTab] = useState<YandexActiveTabs>(
    YandexActiveTabs.upload
  )

  const isUpload = activeTab === YandexActiveTabs.upload
  const isHistory = activeTab === YandexActiveTabs.history

  return (
    <div className="MainPageContentWrapper YandexMainPageContentWrapper">
      <div
        className={`MailingContentTitleWrapper YandexContentTitleWrapper ${
          isHistory ? "YandexContentTitleWrapperExcel" : ""
        }`}
      >
        <Title
          trigger={isMobile ? YandexTabsTitleText.titleTrigger : ``}
          direction={YandexTabsTitleText.tooltipDirection}
          titleText={YandexTabsTitleText.titleText}
          tooltipText={YandexTabsTitleText.tooltipText}
          rightAdditionalCmp={
            isUpload ? (
              <ButtonPrimary
                hidden={isHistory}
                onClick={() =>
                  setModalState({ open: true, editData: undefined })
                }
              >
                {YandexTabsTitleText.add}
              </ButtonPrimary>
            ) : (
              filterState.isActive && (
                <div className="YandexTitleExel">
                  {
                    <ExcelButton
                      url={yandexHistoryConstants.urlXls}
                      requestBody={exelReq}
                    />
                  }
                </div>
              )
            )
          }
          rightAdditionalClassName="MailingContentTitleRight"
          wrapperClass="YandexContentTitleWrapper"
          bottomAdditionalCmp={
            <>
              {filterState.isActive && isHistory && titleDescription}
              <div className="YandexDescription">
                {showDashboardModal && (
                  <div>
                    <QiwiModalComponent
                      close={() => toggleShowDashboardModal(false)}
                      data={dashBoardState.balance?.halls || []}
                      name="sum"
                      title={yandexHistoryConstants.dashboardColumn}
                      color={yandexHistoryConstants.dashboardModalColor}
                    />
                  </div>
                )}
                {filterState.isActive && showDashboard && (
                  <DashboardComponent
                    loading={dashBoardState.pending}
                    config={[
                      {
                        onClick: () => {
                          toggleShowDashboardModal(true)
                        },
                        type: DashboardOptions.yandex_taxi,
                        prefix: DashboardPrefixOptions.RUB,
                        value: `${dashBoardState.balance?.sum}` ?? 0,
                        isCurrency: true,
                        description: `На ${moment(
                          dashBoardState.balance?.date
                        ).format(yandexHistoryConstants.dashboardDateFormat)}`,
                        tooltipText: yandexHistoryConstants.dashboardTooltip
                      }
                    ]}
                  />
                )}
              </div>
            </>
          }
        />
      </div>

      <div className="MailingContentTabs FlexRow">
        <div className="FlexColumn">
          <div
            className={`MailingContentTabsItem ${
              isUpload
                ? `MailingContentTabsItemActive`
                : `MailingContentTabsInactiveItem`
            }`}
            onClick={() => {
              setActiveTab(YandexActiveTabs.upload)
              toggleShowDashboard(false)
            }}
          >
            {YandexTabsTitleText.tabs.upload}
          </div>
          {isUpload && <div className="MailingContentTabsActiveRestriction" />}
        </div>
        <div className="FlexColumn">
          <div
            className={`MailingContentTabsItem ${
              isHistory
                ? `MailingContentTabsItemActive`
                : `MailingContentTabsInactiveItem`
            }`}
            onClick={() => {
              setActiveTab(YandexActiveTabs.history)
              toggleShowDashboard(true)
            }}
          >
            {YandexTabsTitleText.tabs.history}
          </div>
          {isHistory && <div className="MailingContentTabsActiveRestriction" />}
        </div>
      </div>

      <div className="MailingContentTabsRestriction" />

      {isUpload && (
        <YandexFiles setModalState={setModalState} modalState={modalState} />
      )}
      {isHistory && (
        <YandexHistory
          filterState={filterState}
          setFilterState={setFilterState}
          dashBoardState={dashBoardState}
          setDashBoardState={setDashBoardState}
        />
      )}
    </div>
  )
}

export default NonPartnerView
