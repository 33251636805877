import { Moment } from "moment/moment"
import { YandexTableRecord } from "../YandexFilesTable/types"
import { FilesList } from "Components/File/types"

export type YandexModalFormValues = {
  [YandexModalFields.type]: string
  [YandexModalFields.period]?: [Moment, Moment] | Moment
  [YandexModalFields.files]: FilesList[]
}

export type YandexModalProps = {
  editData?: YandexTableRecord
  close: () => void
}

export enum YandexModalFields {
  type = `type`,
  period = `period`,
  files = `files`
}

export type ModalStateProps = {
  open: boolean
  editData: YandexTableRecord | undefined
}
