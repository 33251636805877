import React, { useState } from "react"
import {
  YandexModalFields,
  YandexModalFormValues,
  YandexModalProps
} from "./types"
import { ModalFormComponent } from "Components/Modal"
import {
  FilterDurations,
  PickerDurations,
  PickerFormats
} from "Components/FilterInfoPanel/types"
import { ReactComponent as SuccessIcon } from "Components/icons/illustrations/thumb_up.svg"
import { Form, notification } from "antd"
import { useRequestParams } from "api/hooks"
import { FilesList } from "Components/File/types"
import { UploadFile } from "antd/lib/upload/interface"
import { ButtonPrimary } from "Components/Button"
import { datesFormat, isMobile } from "constants/index"
import { rules } from "Components/Form/rules"
import { SelectComponent } from "Components/Select"
import {
  fileModalConstants,
  formatTableRecordToFormValues,
  initialValues,
  prepareYandexDataToSave,
  yandexFileRule,
  yandexTaxiConstants
} from "../constants"
import DatePickerComponent, {
  RangePickerComponent
} from "Components/DatePicker"
import FileComponent, { UploadComponent } from "Components/File"
import betRequest from "api/request"
import { yandexFileTableFields } from "../YandexFilesTable/constants"
import { disabledDate } from "../helper"

const YandexModal: React.FC<YandexModalProps> = ({ editData, close }) => {
  const isEdit = !!editData
  const [form] = Form.useForm()
  const [pending, setPending] = useState(false)
  const [isSuccess, toggleSuccess] = useState(false)

  const [reqFiles, setReqFiles] = useState<FilesList[]>(
    isEdit ? [editData?.file] : []
  )

  const [fileList, changeFileList] = useState<UploadFile[]>(
    isEdit ? [editData?.file] : []
  )

  const [
    selectedPeriodType,
    setSelectedPeriodType
  ] = useState<null | FilterDurations>(
    editData?.dates[1] ? FilterDurations.monthly : FilterDurations.daily
  )

  const isDaily = selectedPeriodType === FilterDurations.daily

  const { developer, addDeveloperLink, history, logout } = useRequestParams()

  const onFinish = (values: YandexModalFormValues) => {
    setPending(true)

    const requestBody = prepareYandexDataToSave({
      values,
      files: reqFiles
    })

    betRequest({
      developer,
      addDeveloperLink,
      history,
      logout,
      method: `POST`,
      url: fileModalConstants.saveUrl,
      requestBody
    })
      .then(() => {
        setPending(false)
        toggleSuccess(true)
      })
      .catch(({ response }) => {
        setPending(false)
        if (response?.data?.errors) {
          if (response?.data?.errors?.period) {
            form.setFields([
              {
                name: YandexModalFields.period,
                errors: [response?.data?.errors?.period]
              }
            ])
          }

          if (
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length > 0
          ) {
            notification.error({
              message: response.data.errors
            })
          } else {
            notification.error({
              message: Object.values(response?.data?.errors)
            })
          }
        }
      })
  }

  return (
    <ModalFormComponent
      className="YandexModalAddFile"
      close={close}
      width={800}
      title={
        !isSuccess && (
          <>
            {isEdit
              ? fileModalConstants.editTitle
              : fileModalConstants.uploadTitle}
          </>
        )
      }
    >
      {!isSuccess ? (
        <div className="PaymentModalWrapper FlexColumn Gap24">
          <div
            className={isMobile ? `HeadlineThirdText` : `HeadlineFirstText`}
          ></div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={value => {
              if (value[YandexModalFields.type]) {
                setSelectedPeriodType(value[YandexModalFields.type])
              }
            }}
            initialValues={
              editData ? formatTableRecordToFormValues(editData) : initialValues
            }
            autoComplete="off"
          >
            <div className="PaymentModalContentWrapper FlexColumn">
              <div
                className={`PaymentModalFormFirstRow ${
                  isMobile ? `FlexColumn` : `FlexRow Gap24`
                }`}
              >
                {/* Тип Периода */}
                <Form.Item
                  name={YandexModalFields.type}
                  label={
                    <span className="SubtitleFirstText">
                      {fileModalConstants.perodLabel}
                    </span>
                  }
                  rules={rules.required}
                >
                  <SelectComponent
                    allowClear={false}
                    maxLength={100}
                    options={yandexTaxiConstants.periodOptions}
                    value={yandexTaxiConstants.daily}
                  />
                </Form.Item>

                {/* Период */}
                <Form.Item
                  name={YandexModalFields.period}
                  label={
                    <span className="SubtitleFirstText">
                      {yandexFileTableFields.datesTitle}
                    </span>
                  }
                  className="PaymentModalRangePicker"
                  rules={
                    isDaily
                      ? rules.required_date.short
                      : rules.required_date.long
                  }
                >
                  {isDaily ? (
                    <DatePickerComponent
                      format={datesFormat.dayMonthYear}
                      placeholder={fileModalConstants.datePickerPlaceholder}
                      disabledDate={isDaily ? disabledDate : true}
                    />
                  ) : (
                    <RangePickerComponent
                      picker={PickerDurations.month}
                      format={PickerFormats.month}
                      disableFutureDates={true}
                    />
                  )}
                </Form.Item>
              </div>
              <div>
                <div className="FlexRow FlexWrap Gap8">
                  {reqFiles.map((file: FilesList) => (
                    <FileComponent
                      key={`file_${file.name}`}
                      name={file.name}
                      size={file.size}
                      customDownloadLink={file?.url}
                      deleteFile={() => {
                        changeFileList([])
                        setReqFiles([])
                      }}
                    />
                  ))}
                </div>
              </div>
              <div
                className={`PaymentModalBottom ${
                  isMobile ? `FlexColumn` : `FlexRow`
                } Gap16 PaddingTop24 MarginTop16`}
              >
                <ButtonPrimary htmlType="submit" disabled={pending}>
                  {isEdit ? fileModalConstants.save : fileModalConstants.upload}
                </ButtonPrimary>
                <div className="FlexRow YandexModalFileDescription">
                  <div
                    className={
                      fileList.length
                        ? `UploadComponentFormWrapper`
                        : `UploadComponentFormError`
                    }
                  >
                    <Form.Item
                      name={YandexModalFields.files}
                      rules={[
                        {
                          validator: () => yandexFileRule(!!fileList.length)
                        }
                      ]}
                    >
                      <UploadComponent
                        fileList={fileList}
                        pending={pending}
                        setPending={setPending}
                        changeFileList={changeFileList}
                        allowedTypes={fileModalConstants.allowed}
                        addFile={data => setReqFiles([data])}
                        maxSize={10}
                        maxCount={1}
                      />
                    </Form.Item>
                  </div>
                  <div className="FlexColumn Gap2 Padding8">
                    <span className="CaptionText Gray600Color">
                      {fileModalConstants.description}
                    </span>
                    <span className="CaptionText Gray600Color">
                      {fileModalConstants.format}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <div className="FlexColumn Gap24">
          <div className="GridCenter">
            <span className="HeadlineFirstText">{"Файл успешно загружен"}</span>
          </div>
          <div className="GridCenter">
            <SuccessIcon />
          </div>
          <div className="GridCenter PaddingBottom40">
            <ButtonPrimary onClick={close}>
              {fileModalConstants.ok}
            </ButtonPrimary>
          </div>
        </div>
      )}
    </ModalFormComponent>
  )
}

export default YandexModal
